import { z } from "zod";

import type {
  BookingStatus,
  CashLocationMutationType,
  CashLocationType,
  DeviceConnectionTypes,
  FloorPlanItemBackgroundType,
  FloorPlanItemType,
  ModuleTypes,
  OrderTypes,
  PaymentMethods,
  Permissions,
  ProductModifierTypes,
  ProductModifierValueType,
  ShiftState,
  ShiftType,
  Status,
  SupportedLanguages,
  TableShapes,
  TableTypes,
  UserTypes,
} from "@vrodex/db";
import {
  OrderLineTypes,
  PaymentStatus,
  RegisterButtonLocation,
  RegisterFunctions,
} from "@vrodex/db";

const THEMES = {
  light: "light",
  dark: "dark",
  light_gold: "light-gold",
  dark_gold: "dark-gold",
} as const;

export type Themes = keyof typeof THEMES;

export const OrderLineCommentSchema = z.object({
  comment: z.string().min(1),
});

export const TableSchema = z.object({
  reference: z.string().min(1),
  seats: z.number(),
  location: z.string().uuid(),
  width: z.number(),
  height: z.number(),
  posX: z.number(),
  posY: z.number(),
  rotation: z.number(),
  shape: z.custom<TableShapes>(),
  type: z.custom<TableTypes>(),
  status: z.custom<Status>(),
});

export const BookingSchema = z.object({
  customer: z.object({
    firstName: z
      .string()
      .optional()
      .or(z.literal(""))
      .transform((val) => (val === "" ? undefined : val)),
    lastName: z
      .string()
      .optional()
      .or(z.literal(""))
      .transform((val) => (val === "" ? undefined : val)),
    email: z
      .string()
      .email()
      .optional()
      .or(z.literal(""))
      .transform((val) => (val === "" ? undefined : val)),
    phone: z
      .string()
      .optional()
      .or(z.literal(""))
      .transform((val) => (val === "" ? undefined : val)),
  }),
  label: z.string().uuid().optional(),
  employee: z.string().uuid().optional(),
  duration: z.number(),
  table: z.string().uuid().optional(),
  memo: z.string().optional(),
  groupSize: z.number(),
  date: z.date(),
  status: z.custom<BookingStatus>(),
});

export const BookingStatusUpdateSchema = z.object({
  id: z.string().uuid(),
  bookingStatus: z.custom<BookingStatus>(),
  employee: z.string().uuid().optional(),
});

export const EmployeeSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().optional(),
  code: z.string(),
  email: z.string().optional(),
  language: z.custom<SupportedLanguages>(),
  hourlyRate: z.number().optional(),
  dateOfBirth: z.date().optional(),
  employedSinceDate: z.date().optional(),
  color: z.string().optional(),
  group: z.string().uuid(),
});

export const FunctionButtonSchema = z.object({
  position: z.number(),
  mobile: z.boolean(),
  registerFunction: z.nativeEnum(RegisterFunctions),
  location: z.nativeEnum(RegisterButtonLocation),
  color: z
    .string()
    .optional()
    .or(z.literal("").transform((val) => (val === "" ? undefined : val))),
  label: z
    .string()
    .optional()
    .or(z.literal("").transform((val) => (val === "" ? undefined : val))),
});

export const FloorPlanItemSchema = z.object({
  reference: z.string().optional(),
  background: z.string().optional().nullable(),
  backgroundType: z.custom<FloorPlanItemBackgroundType>(),
  pattern: z.string().optional().nullable(),
  borderRadius: z.string().optional(),
  location: z.string().uuid(),
  width: z.number(),
  layer: z.number().optional(),
  height: z.number(),
  scale: z.number().optional(),
  posX: z.number(),
  posY: z.number(),
  rotation: z.number(),
  type: z.custom<FloorPlanItemType>().optional(),
  status: z.custom<Status>(),
  locked: z.boolean(),
});

export const CustomFloorPlanItemSchema = z.object({
  location: z.string().uuid(),
  width: z.number(),
  height: z.number(),
  posX: z.number(),
  posY: z.number(),
  rotation: z.number(),
  status: z.custom<Status>(),
});

export const PrinterSchema = z.object({
  name: z.string().min(1),
  connectionType: z.custom<DeviceConnectionTypes>(),
  systemName: z.string().optional(),
  port: z.number().optional(),
  ipAddress: z.string().optional(),
  width: z.number(),
  printCategories: z.boolean(),
  copies: z.number(),
  beeps: z.number(),
  cashLocation: z
    .string()
    .uuid()
    .optional()
    .or(z.literal("").transform((val) => (val === "" ? undefined : val))),
});

export const PrinterSchemaWithId = PrinterSchema.extend({
  id: z.string().uuid(),
});

export const PrintRouteSchema = z.object({
  name: z.string().min(1),
  status: z.custom<Status>(),
  printers: z.array(z.string().uuid()),
});

export const CourseSchema = z.object({
  name: z.string().min(1),
  status: z.custom<Status>(),
  color: z.string().optional(),
});

export const ProductSchema = z.object({
  name: z.string().min(1),
  preparationName: z
    .string()
    .optional()
    .or(z.literal(""))
    .transform((val) => (val === "" ? undefined : val)),
  regularPrice: z.number(),
  pickupPrice: z.number().optional(),
  category: z.string().uuid(),
  color: z.string().optional(),
  course: z.string().uuid().optional(),
  status: z.custom<Status>(),
  printer: z.string().uuid().optional(),
  printRoute: z.string().uuid().optional(),
  vatRate: z.string().uuid(),
  position: z.number(),
  description: z.string().optional(),
});

export const DayReportSchema = z.object({
  printer: z.string().uuid(),
  employee: z.string().uuid().optional(),
  range: z
    .object({
      start: z.date(),
      end: z.date(),
    })
    .optional(),
});

export const MutationSchema = z.object({
  value: z.number(),
  category: z.string().uuid().optional(),
  type: z.custom<CashLocationMutationType>(),
  comment: z.string().optional(),
  cashLocation: z.string().uuid(),
  vatRate: z.number().optional(),
  employee: z.string().uuid().optional(),
});

export const MutationCategorySchema = z.object({
  name: z.string(),
});

export const ProductExtraSchema = z.object({
  name: z.string().min(1),
  regularPrice: z.number(),
  vatRate: z.number(),
  type: z.custom<ProductModifierTypes>(),
  valueType: z.custom<ProductModifierValueType>(),
});

export const ProductCategorySchema = z.object({
  name: z.string().min(1),
  description: z.string().optional(),
  position: z.number(),
  status: z.custom<Status>(),
  color: z.string().optional(),
  printRoute: z.string().uuid().optional(),
  course: z.string().uuid().optional(),
  vatRate: z.string().uuid(),
});

export const LoginFormSchema = z.object({
  user: z.string().trim().email(),
  password: z.string().trim().min(1),
});

export const GoogleFetchAddressSchema = z.object({
  streetName: z.string(),
  streetNumber: z.string(),
  zipCode: z.string(),
});

export const SetupFormSchema = z.object({
  companyName: z.string(),
  country: z.string(),
  zipCode: z.string(),
  streetName: z.string(),
  streetNumber: z.string(),
  lat: z.number(),
  lon: z.number(),
  city: z.string(),
  modules: z.array(z.custom<ModuleTypes>()).min(1),
});

export const SignUpFormSchema = z.object({
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  email: z.string().email(),
  phoneNumber: z.string(),
  companyName: z.string(),
  companyType: z.custom<UserTypes>(),
  reference: z.string().optional(),
  password: z.string().min(5),
});

export const LocationSchema = z.object({
  name: z.string().min(1),
  status: z.custom<Status>(),
  position: z.number(),
});

export const EmployeeGroupSchema = z.object({
  name: z.string().min(1),
  permissions: z.array(z.custom<Permissions>()),
});

export const ReceiptSettingsSchema = z.object({
  receiptHeader: z.string().optional(),
  receiptFooter: z.string().optional(),
  qrValue: z.string().optional(),
  drawerPrinterId: z.string().optional(),
  logo: z
    .object({
      id: z.string(),
      url: z.string(),
    })
    .optional(),
});

export const IntegrationsFormSchema = z.object({
  googleReserve: z.boolean(),
  mollie: z.boolean(),
});

export const BookingSettingsFormSchema = z.object({
  notificationEmail: z
    .string()
    .optional()
    .or(z.literal(""))
    .transform((val) => (val === "" ? undefined : val)),
  lastAllowed: z.number(),
  maxPartySize: z.number(),
  minPartySize: z.number(),
  maxDaysInAdvance: z.number(),
  defaultDuration: z.number(),
  autoAcceptUnder: z.number(),
  downPayment: z
    .number()
    .optional()
    .or(z.literal(""))
    .transform((val) => (val === "" ? undefined : val)),
});

export const CashLocationFormSchema = z.object({
  name: z.string(),
  type: z.custom<CashLocationType>(),
});

export const AppearanceSettingsSchema = z.object({
  theme: z.custom<Themes>().optional(),
});

export const GeneralSettingsSchema = z.object({
  idleTimer: z.number().min(0),
  homePage: z.string(),
  language: z.custom<SupportedLanguages>(),
});

export const OrderLineSchema = z.array(
  z.object({
    reference: z.string().optional().nullable(),
    productId: z.string().uuid().optional(),
    createdAt: z.string().optional(),
    printerId: z.string().uuid().optional(),
    printRouteId: z.string().uuid().optional(),
    serviceId: z.string().uuid().optional(),
    type: z.custom<OrderLineTypes>().optional(),
    name: z.string(),
    preparationName: z.string().optional(),
    parent: z.string().optional(),
    course: z
      .object({
        id: z.string().uuid(),
        name: z.string(),
      })
      .optional(),
    category: z
      .object({
        id: z.string().uuid(),
        name: z.string(),
      })
      .optional(),
    position: z.number(),
    paymentStatus: z.custom<PaymentStatus>(),
    quantity: z.number(),
    price: z.number(),
    vatRate: z.number().optional(),
    internalMemo: z.string().optional(),
    comment: z.string().optional(),
  }),
);

export const PaymentSchema = z.array(
  z.object({
    paymentMethod: z.object({
      name: z.string(),
      type: z.custom<PaymentMethods>(),
      id: z.string().uuid(),
      cashLocation: z.string().uuid().optional(),
    }),
    amount: z.number(),
  }),
);

export const CheckoutSchema = z
  .object({
    order: z
      .object({
        reference: z.string().optional(),
      })
      .optional(),
    table: z.string().uuid().optional(),
    drawer: z.string().uuid().optional(),
    employee: z.string().uuid().optional(),
    checkoutLines: OrderLineSchema,
    payment: PaymentSchema,
  })
  .superRefine((val, ctx) => {
    const totalPaid = val.payment.reduce((acc, curr) => acc + curr.amount, 0);
    const itemsTotal = val.checkoutLines
      .filter(
        (line) =>
          (line.type === OrderLineTypes.product ||
            line.type === OrderLineTypes.extra) &&
          line.paymentStatus === PaymentStatus.in_progress,
      )
      .reduce((total, line) => total + line.price * line.quantity, 0);

    const discountTotal = val.checkoutLines
      .filter(
        (line) =>
          line.type === OrderLineTypes.discount &&
          line.paymentStatus === PaymentStatus.in_progress,
      )
      .reduce((total, line) => total + line.price * line.quantity, 0);

    const totalToPay = itemsTotal - discountTotal;

    if (totalPaid < totalToPay) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: totalToPay,
        type: "number",
        inclusive: true,
        path: ["payment"],
      });
    }
  });

export const DeleteOrderSchema = z.string().uuid();

export const TransferOrderSchema = z.object({
  order: z.string().uuid(),
  table: z.string().uuid(),
});

export const CloseRegisterSchema = z.object({
  drawer: z.string().uuid(),
  attempts: z.number(),
  comment: z.string().optional(),
  cardValue: z.number(),
  drawerValue: z.array(
    z.object({
      unit: z.number(),
      value: z.number(),
    }),
  ),
});

export const CompanySettingsFormSchema = z.object({
  userId: z.string().uuid(),
  companyName: z.string(),
  phoneNumber: z.string(),
  zipCode: z.string(),
  streetName: z.string(),
  streetNumber: z.string(),
  city: z.string(),
  country: z.string(),
});

export const WidgetBookingSchema = z.object({
  groupSize: z.number(),
  date: z.date(),
  timeslot: z.date(),
  userId: z.string().uuid(),
  comment: z.string().optional(),
  customer: z.object({
    language: z.custom<SupportedLanguages>(),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    phone: z.string().min(1),
    email: z.string().min(1),
    newsletter: z.boolean(),
  }),
});

export const OpenDrawerSchema = z.object({
  drawer: z.string().uuid(),
  printer: z.string().uuid().optional(),
  employee: z.string().uuid().optional(),
});

export const OrderSchema = z.object({
  reference: z.string().optional(),
  discount: z.number().optional(),
  type: z.custom<OrderTypes>(),
  printPreparationReceipt: z.boolean().optional(),
  printReceipt: z.boolean().optional(),
  drawerId: z.string().optional(),
  tableId: z.string().uuid().optional().nullable(),
  employeeId: z.string().uuid().optional(),
  payment: z.array(
    z.object({
      id: z.string(),
      amount: z.number(),
      type: z.custom<PaymentMethods>(),
    }),
  ),
  lines: OrderLineSchema.optional(),
});

export const PrintProFormaReceiptSchema = z.object({
  employeeId: z.string().uuid().optional(),
  printerId: z.string().uuid().optional(),
  orderId: z.string().uuid(),
});

export const PrintReceiptSchema = z.object({
  employeeId: z.string().uuid().optional(),
  printerId: z.string().uuid().optional(),
  receiptId: z.string().uuid(),
});

export const PrintMutationSchema = z.object({
  employeeId: z.string().uuid().optional(),
  printerId: z.string().uuid().optional(),
  mutationId: z.string().uuid(),
});

export const ShiftSchema = z.object({
  startDate: z.date(),
  endDate: z.date().optional(),
  startTime: z.date(),
  totalSpots: z.number(),
  endTime: z.date(),
  type: z.custom<ShiftType>(),
  state: z.custom<ShiftState>(),
  description: z.string().optional(),
});

export const PrintPreparationReceiptSchema = z.object({
  employeeId: z.string().uuid().optional(),
  printerId: z.string().uuid().optional(),
  orderId: z.string().uuid(),
});
