export enum Allergies {
  nuts = "nuts",
  dairy = "dairy",
  vegan = "vegan",
  vegetarian = "vegetarian",
  fish = "fish",
  veal = "veal",
  pork = "pork"
}

export enum BookingEditType {
  create = "create",
  update = "update",
  delete = "delete",
  notification = "notification"
}

export enum BookingStatus {
  active = "active",
  pending = "pending",
  canceled = "canceled",
  hold = "hold",
  expired = "expired",
  no_show = "no_show",
  checked_in = "checked_in",
  checked_out = "checked_out"
}

export enum ButtonLocations {
  cash_register_footer = "cash_register_footer",
  floor_plan_footer = "floor_plan_footer",
  cash_register_side = "cash_register_side",
  floor_plan_side = "floor_plan_side"
}

export enum CashLocationMutationCategoryType {
  tip_from_cash = "tip_from_cash",
  tip_from_card_transactions = "tip_from_card_transactions",
  other = "other",
  difference = "difference",
  evaluation = "evaluation"
}

export enum CashLocationMutationType {
  in = "in",
  out = "out",
  evaluation = "evaluation",
  open_drawer = "open_drawer",
  deposit = "deposit"
}

export enum CashLocationReportType {
  open = "open",
  close = "close"
}

export enum CashLocationType {
  drawer = "drawer",
  employee = "employee",
  safe = "safe",
  bank = "bank"
}

export enum ClockTypes {
  military = "military",
  regular = "regular"
}

export enum CouponAdjustmentTypes {
  percentage = "percentage",
  fixed = "fixed"
}

export enum CouponFilters {
  all_products = "all_products",
  products_in_category = "products_in_category",
  selected_products = "selected_products"
}

export enum CouponTypes {
  buy_x_get_x = "buy_x_get_x",
  buy_x_get_y = "buy_x_get_y",
  cart_adjustment = "cart_adjustment",
  product_adjustment = "product_adjustment",
  free_shipping = "free_shipping",
  bulk = "bulk"
}

export enum DayOfWeek {
  sunday = "sunday",
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
  saturday = "saturday"
}

export enum DeliveryAreaTypes {
  radius = "radius",
  zip_code = "zip_code",
  custom = "custom"
}

export enum DeviceConnectionTypes {
  usb = "usb",
  network = "network",
  serial = "serial"
}

export enum DeviceLoginStatus {
  pending = "pending",
  declined = "declined",
  approved = "approved"
}

export enum DeviceTypes {
  printer = "printer",
  smartphone = "smartphone"
}

export enum EmployeeClockingReportType {
  in = "in",
  out = "out",
  break_start = "break_start",
  break_end = "break_end"
}

export enum EmployeeGroupTypes {
  manager = "manager",
  default = "default",
  custom = "custom"
}

export enum EventStatus {
  expected = "expected",
  pending = "pending",
  hold = "hold",
  no_show = "no_show",
  checked_in = "checked_in",
  checked_out = "checked_out",
  canceled = "canceled",
  expired = "expired"
}

export enum EventType {
  booking_reminder = "booking_reminder",
  booking_review = "booking_review",
  note_reminder = "note_reminder"
}

export enum ExternalServices {
  mollie = "mollie",
  thuisbezorgd = "thuisbezorgd",
  ubereats = "ubereats",
  deliveroo = "deliveroo"
}

export enum FeatureFlags {
  split_bill = "split_bill",
  transfer_table = "transfer_table",
  table_discount = "table_discount",
  order_comment = "order_comment"
}

export enum FeeTypes {
  transaction = "transaction",
  item = "item",
  google_reserve = "google_reserve",
  booking_widget = "booking_widget"
}

export enum FeedbackTypes {
  booking = "booking",
  order = "order"
}

export enum FloorPlanItemBackgroundType {
  solid = "solid",
  gradient = "gradient",
  texture = "texture",
  pattern = "pattern",
  no_fill = "no_fill"
}

export enum FloorPlanItemType {
  plant_1 = "plant_1",
  toilet_1 = "toilet_1",
  kitchen_stove_1 = "kitchen_stove_1",
  wall_1 = "wall_1",
  floor_1 = "floor_1",
  floor_2 = "floor_2",
  floor_3 = "floor_3",
  bar_straight_1 = "bar_straight_1",
  bar_corner_1 = "bar_corner_1",
  bar_block_1 = "bar_block_1",
  custom = "custom",
  door = "door",
  table = "table"
}

export enum ImageTypes {
  product = "product",
  product_category = "product_category",
  booking_widget = "booking_widget"
}

export enum LogTypes {
  booking_created_manual = "booking_created_manual",
  booking_created_online = "booking_created_online",
  booking_confirmation_email_sent = "booking_confirmation_email_sent",
  booking_review_email_sent = "booking_review_email_sent",
  booking_reminder_email_sent = "booking_reminder_email_sent",
  booking_status_changed = "booking_status_changed",
  booking_deleted = "booking_deleted",
  booking_imported = "booking_imported",
  booking_recovered = "booking_recovered",
  booking_feedback_received = "booking_feedback_received",
  booking_canceled_by_customer = "booking_canceled_by_customer",
  booking_time_modified_by_customer = "booking_time_modified_by_customer",
  booking_time_modified = "booking_time_modified",
  booking_group_size_modified = "booking_group_size_modified",
  booking_group_size_modified_by_customer = "booking_group_size_modified_by_customer",
  booking_down_payment_received = "booking_down_payment_received",
  order_accepted = "order_accepted",
  special_day_created = "special_day_created",
  special_day_updated = "special_day_updated",
  special_day_deleted = "special_day_deleted"
}

export enum ModuleTypes {
  cash_register = "cash_register",
  ordering = "ordering",
  booking = "booking",
  table = "table",
  google_reserve_integration = "google_reserve_integration",
  the_fork_integration = "the_fork_integration",
  social_deal_integration = "social_deal_integration",
  just_eat_integration = "just_eat_integration",
  uber_eats_integration = "uber_eats_integration",
  gold_pack = "gold_pack",
  silver_pack = "silver_pack",
  hosted_domain = "hosted_domain",
  platform_access = "platform_access",
  thuisbezorgd_integration = "thuisbezorgd_integration"
}

export enum NotificationTypes {
  new_order = "new_order",
  new_booking = "new_booking",
  canceled_order = "canceled_order",
  canceled_booking = "canceled_booking",
  updated_booking = "updated_booking",
  employee_sign_in = "employee_sign_in",
  new_booking_request = "new_booking_request"
}

export enum OpeningHourTypes {
  delivery = "delivery",
  pickup = "pickup",
  store = "store"
}

export enum OrderEditType {
  create = "create",
  add_item = "add_item",
  remove_item = "remove_item",
  delete = "delete",
  receipt = "receipt",
  checkout = "checkout",
  transfer = "transfer",
  visit = "visit"
}

export enum OrderLineStatus {
  active = "active",
  removed = "removed"
}

export enum OrderLineTypes {
  product = "product",
  comment = "comment",
  discount = "discount",
  extra = "extra"
}

export enum OrderStatus {
  new = "new",
  accepted = "accepted",
  in_progress = "in_progress",
  canceled = "canceled",
  in_delivery = "in_delivery"
}

export enum OrderTypes {
  delivery = "delivery",
  pickup = "pickup",
  location = "location"
}

export enum PaymentMethodUsabilityTypes {
  location = "location",
  delivery = "delivery",
  pickup = "pickup"
}

export enum PaymentMethods {
  ideal = "ideal",
  cash = "cash",
  creditcard = "creditcard",
  paypal = "paypal",
  other = "other",
  card = "card"
}

export enum PaymentStatus {
  paid = "paid",
  pending = "pending",
  canceled = "canceled",
  refunded = "refunded",
  in_progress = "in_progress"
}

export enum Periods {
  always = "always",
  custom = "custom"
}

export enum Permissions {
  view_order = "view_order",
  create_order = "create_order",
  update_order = "update_order",
  delete_order = "delete_order",
  view_booking = "view_booking",
  create_booking = "create_booking",
  update_booking = "update_booking",
  delete_booking = "delete_booking",
  view_product = "view_product",
  create_product = "create_product",
  update_product = "update_product",
  delete_product = "delete_product",
  view_product_category = "view_product_category",
  create_product_category = "create_product_category",
  update_product_category = "update_product_category",
  delete_product_category = "delete_product_category",
  view_employee = "view_employee",
  create_employee = "create_employee",
  update_employee = "update_employee",
  delete_employee = "delete_employee",
  view_employee_group = "view_employee_group",
  create_employee_group = "create_employee_group",
  update_employee_group = "update_employee_group",
  delete_employee_group = "delete_employee_group",
  open_drawer = "open_drawer",
  close_drawer = "close_drawer",
  checkout_order = "checkout_order",
  discount_order = "discount_order",
  delete_item_from_order = "delete_item_from_order",
  update_item_from_order = "update_item_from_order",
  view_settings = "view_settings",
  view_billing = "view_billing",
  view_reports = "view_reports",
  view_table = "view_table",
  create_table = "create_table",
  update_table = "update_table",
  delete_table = "delete_table",
  create_mutation = "create_mutation",
  print = "print",
  create_shift = "create_shift",
  update_shift = "update_shift",
  delete_shift = "delete_shift"
}

export enum PriceType {
  location = "location",
  delivery = "delivery",
  take_away = "take_away"
}

export enum PrintOperations {
  receipt = "receipt",
  preparation_receipt = "preparation_receipt",
  preliminary_receipt = "preliminary_receipt",
  day_report = "day_report",
  mutation = "mutation",
  open_drawer = "open_drawer"
}

export enum PrintingStatus {
  pending = "pending",
  failed = "failed",
  success = "success",
  canceled = "canceled"
}

export enum ProductModifierTypes {
  addition = "addition",
  subtraction = "subtraction"
}

export enum ProductModifierValueType {
  fixed = "fixed",
  percentage = "percentage"
}

export enum ProductTypes {
  product = "product",
  modifier = "modifier"
}

export enum ProductVisibilityTypes {
  location = "location",
  pickup = "pickup",
  delivery = "delivery"
}

export enum Providers {
  thuisbezorgd = "thuisbezorgd",
  allindex = "allindex",
  ubereats = "ubereats",
  deliveroo = "deliveroo",
  other = "other",
  the_fork = "the_fork",
  eet_nu = "eet_nu",
  social_deal = "social_deal",
  google_reserve = "google_reserve",
  widget = "widget"
}

export enum RegisterButtonLocation {
  footer = "footer",
  sidebar_right = "sidebar_right",
  floor_plan_footer = "floor_plan_footer",
  calendar_footer = "calendar_footer",
  dashboard_footer = "dashboard_footer",
  dashboard_sidebar = "dashboard_sidebar",
  calendar_sidebar = "calendar_sidebar",
  floor_plan_sidebar = "floor_plan_sidebar"
}

export enum RegisterFunctions {
  print_preliminary_receipt = "print_preliminary_receipt",
  order = "order",
  checkout = "checkout",
  table_overview = "table_overview",
  open_drawer = "open_drawer",
  misc_product = "misc_product",
  day_overview = "day_overview",
  drawer_mutation = "drawer_mutation",
  close_register = "close_register",
  transfer_table = "transfer_table",
  delete_order = "delete_order",
  order_discount = "order_discount",
  order_comment = "order_comment",
  functions_overview = "functions_overview",
  cancel_operation = "cancel_operation",
  new_booking = "new_booking",
  calendar = "calendar",
  pause_shift = "pause_shift",
  table_next_round = "table_next_round",
  dashboard = "dashboard",
  quick_comment = "quick_comment"
}

export enum ShiftState {
  blank = "blank",
  recurring = "recurring",
  absent = "absent",
  single = "single",
  override = "override"
}

export enum ShiftType {
  bookings = "bookings",
  delivery = "delivery",
  take_away = "take_away",
  store = "store"
}

export enum States {
  open = "open",
  busy = "busy",
  closed = "closed"
}

export enum Status {
  active = "active",
  inactive = "inactive",
  trashed = "trashed"
}

export enum SubscriptionIntervals {
  month = "month",
  year = "year",
  week = "week",
  once = "once"
}

export enum SubscriptionStatus {
  active = "active",
  not_active = "not_active"
}

export enum SubscriptionType {
  max = "max",
  regular = "regular",
  addon = "addon"
}

export enum SupportedCurrencies {
  EUR = "EUR",
  USD = "USD",
  GBP = "GBP"
}

export enum SupportedLanguages {
  nl = "nl",
  en = "en",
  fr = "fr",
  de = "de"
}

export enum TableShapes {
  square = "square",
  round = "round"
}

export enum TableTypes {
  location = "location",
  take_away = "take_away"
}

export enum TemplateSendType {
  sms = "sms",
  email = "email"
}

export enum TemplateType {
  booking_confirmation = "booking_confirmation",
  booking_pending_confirmation = "booking_pending_confirmation",
  booking_rejection = "booking_rejection",
  booking_reminder = "booking_reminder",
  booking_no_show = "booking_no_show",
  booking_moved = "booking_moved",
  booking_canceled = "booking_canceled",
  booking_digital_invoice = "booking_digital_invoice",
  booking_modified = "booking_modified",
  booking_review = "booking_review"
}

export enum TemplateTypes {
  booking_confirmation = "booking_confirmation",
  booking_canceled = "booking_canceled",
  booking_pending_confirmation = "booking_pending_confirmation",
  booking_moved = "booking_moved",
  booking_digital_invoice = "booking_digital_invoice",
  booking_rejected = "booking_rejected",
  order_confirmation = "order_confirmation",
  order_canceled = "order_canceled",
  order_pending_confirmation = "order_pending_confirmation",
  order_delivered = "order_delivered"
}

export enum TimeslotType {
  bookings = "bookings",
  delivery = "delivery",
  take_away = "take_away",
  store = "store"
}

export enum TokenGrants {
  booking_widget = "booking_widget",
  read_products = "read_products"
}

export enum UserBranch {
  production = "production",
  staging = "staging",
  testing = "testing",
  training = "training"
}

export enum UserRole {
  user = "user",
  admin = "admin",
  demo = "demo"
}

export enum UserTypes {
  restaurant = "restaurant",
  store = "store",
  hair_salon = "hair_salon",
  beauty_salon = "beauty_salon",
  other = "other",
  snackbar = "snackbar",
  bakery = "bakery"
}

export enum VisibilityTypes {
  local = "local",
  direct = "direct",
  online = "online"
}